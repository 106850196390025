import React from 'react'
import './experience.css'
import GA from '../../assets/general-assembly.jpg'
import AR from '../../assets/architecture.jpg'


const Experience = () => {
  return (
    <section id='experience'>
     
      <h1> Work Experience</h1>

      <div className="container experience_container">
        <article className="experience">
          <div className="experience_head">
            <h3>Software Engineer Graduate</h3>
          </div>
          <img src={GA} alt="General Assembly logo" className='experience-image' />
          <div className='experience_text'>
          <p> The Software Engineering Immersive course at General Assembly was an intensive bootcamp with approximately 12 hours a day for 3 consecutive months involving 8 hours of in-class sessions and around 4 hours of homework to practise what we have learnt in class. 
          </p>
          <p> We were given a week to produce a project after being taught a specific topic every two weeks which include different languages, frontend frameworks and backend frameworks.  
          </p>
          </div>
        </article>

         <article className="experience">
          <div className="experience_head">
            <h3>Architect ( 8 years )</h3>
          </div>
              <img src={AR} alt="" className='experience-image' />
            <div className='experience_text'>
              <p> I worked on different architecture sectors such as health, transportation, commercial, and residential in my previous role as an architect. Having worked on different sectors, made me quite an adaptable character and a quick learner.
              </p>
              <p>
              With this previous experience, what I learnt is that the key to pick up new stuff is to understand how each sector shares common similarities and how they differentiate from one another. It is all about getting the right tools for the job!
              </p>
            </div>
        </article>

      </div>




    </section>
  )
}

export default Experience