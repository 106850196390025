import React from 'react'
import CV from '../../assets/Philippa Choong Resume.pdf'
import {useState} from 'react'

const CTA = () => {
  const [activeNav, setActiveNav] = useState('#');

  return (
    <div className="cta">
        {/* <div className='tooltip_CTA'>
          <a href={CV} target="_blank" className='btn'> Resume </a>
          <span className='tooltiptext_CTA'>Click for CV</span>
        </div> */}

         <div className='tooltip_CTA'>
          <a href="#footer" onClick={() => setActiveNav('#footer')} className={activeNav === '#footer' ? 'active' : 'btn'} >Let's Chat</a>
          <span className='tooltiptext_CTA'>Contact Me!</span>
        </div>
    </div>  
  )
}

export default CTA