import React from 'react'
import './skills.css'
import HTML from '../../assets/html.png'
import CSS from '../../assets/css.png'
import JS from '../../assets/javascript.png'
import JQ from '../../assets/jquery.png'
import RT from '../../assets/react.png'
import VUE from '../../assets/vue.png'

import RL from '../../assets/rails.png'
import RB from '../../assets/ruby.png'
import SQL from '../../assets/sql.png'
import MNG from '../../assets/mongo.png'
import ND from '../../assets/node.png'

import TJ from '../../assets/threejs.png'
import SP from '../../assets/sonicpi.png'
import TS from '../../assets/typescript.png'
  import PY from '../../assets/python.png'



const Experience = () => {
  return (
    <section id='skills'>

    <h1> Skills </h1>

    <div className='container skills_container'>
      <div className='skills_frontend'>
        <h3>Frontend Development</h3>
          <div className='skills_frontend_icons'>
            <div className='skills_content'>
              <img src={HTML} alt="HTML" className='skills_details' />
            </div>
            <div className='skills_content'>
              <img src={CSS} alt="CSS" className='skills_details' />
            </div>
            <div className='skills_content'>
              <img src={JS} alt="Javascript" className='skills_details' />
            </div>
            <div className='skills_content'>
              <img src={JQ} alt="JQuery" className='skills_details' />
            </div>
            <div className='skills_content'>
              <img src={RT} alt="React" className='skills_details' />
            </div>
            <div className='skills_content'>
              <img src={VUE} alt="Vue" className='skills_details' />
            </div>
        </div>
      </div>



      <div className='skills_backend'>  
        <h3>Backend Development</h3>
          <div className='skills_frontend_icons'>
            <div className='skills_content'>
              <img src={RL} alt="Rails" className='skills_details'/>
            </div>
            <div className='skills_content'>
              <img src={RB} alt="Ruby" className='skills_details'/>
            </div>
            <div className='skills_content'>
              <img src={SQL} alt="SQL" className='skills_details'/>
            </div>
            <div className='skills_content'>
              <img src={MNG} alt="MongoDB" className='skills_details'/>
            </div>
            <div className='skills_content'>
              <img src={ND} alt="Node" className='skills_details'/>
            </div>

        </div>

      </div>
    </div>



    <div className='skills_tolearn_container'>
      <div className='skills_tolearn'>
        <h3> Future Skills to Work On </h3>
          <div className='skills_frontend_icons'>
            <div className='skills_content'>
                <img src={TJ} alt="three js" className='skills_details'/>
            </div>
            <div className='skills_content'>
                <img src={SP} alt="sonic pi" className='skills_details'/>
            </div>
            <div className='skills_content'>
                <img src={TS} alt="typescript" className='skills_details'/>
            </div>
            <div className='skills_content'>
                <img src={PY} alt="python" className='skills_details'/>
            </div>
          </div>
      </div>
    </div>


    </section>
  )
}

export default Experience