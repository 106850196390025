
import React from 'react'
import './about.css'
import ME from '../../assets/me.jpg'
import {TbAward} from 'react-icons/tb'
import {MdOutlineDraw} from 'react-icons/md'
import {MdArchitecture} from 'react-icons/md'
import {MdPets} from 'react-icons/md'
import PET from '../../assets/pets2.gif'
import PASTTIME from '../../assets/hobbies.gif'


const About = () => {
  return (
    <section id='about'>
    
      <h1>About Me</h1>

      <div className='container about_container'>
       <div className='about_me'>
          <div className='about_me-image'>
            <img src={ME} alt="About me Image" />
          </div>
       </div>

       <div className='about_content'>
          <div className='about_cards'>

            <article className='about_card'>
              <img src={PASTTIME} alt="pasttime" />
              <h3> Past Time </h3>
              
            </article>

            

            <article className='about_card'>
              <img src={PET} alt="fur babies" />
              <h3> My fur babies</h3>
            </article>

          </div>

            <p>During my architecture career, I was always intrigued with the advanced technologies especially when I used different visualization and modeling softwares for my projects. Also, how the current web development has improved the communication between multi-disciplinary teams. At last, I am finally part of the tech industry!</p>
            <p>
              Working in different architecture sectors, made me quite an adaptable and a resilient character. The top skills I bring to the table are problem solving, managing stakeholders, adaptability, and a passion to learn and collaborate with my team.
            </p>

            <p> Aside of this, a little bit about myself outside of work. I love gardening and drawing. I participate in weekly watercolor painting class and have exhibited a couple of artworks at the community townhall.  </p>

            <p> Hit me up on LinkedIn or my email to get in touch with me! </p>
            
       </div>

      </div>
    
    </section>
  )
}

export default About