import React from 'react'
import {FaLinkedinIn} from 'react-icons/fa'
import {BsGithub} from 'react-icons/bs'
import {MdOutlineEmail} from 'react-icons/md'

const HeaderSocials = () => {

   const copy = () => {
    navigator.clipboard.writeText("pippachoong@gmail.com");
    alert("Email address copied!");
  }
  

  
  return (
    <div className="header_socials">
        <a href="https://www.linkedin.com/in/philippachoong" target="_blank"><FaLinkedinIn/></a>
        <a href="https://github.com/pippachoong" target="_blank"><BsGithub/></a>
        <div onClick={copy}><MdOutlineEmail/></div>
    </div>
  )
}

export default HeaderSocials