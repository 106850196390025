import React from 'react'
import './portfolio.css'
import GAME from '../../assets/hip_and_hop.gif'
import DES from '../../assets/despination.gif'
import BON from '../../assets/bon-appetite.gif'
import TIC from '../../assets/tictac.gif'


const data = [
  {
    id: 1, 
    image: GAME,
    title: 'Hip & Hop',
    description: 'This is a recent collaboration project with another team mate where we challenged ourselves to pick up a new library called Phaser and a backend comprising of Express & MongoDB where each of us has to learn and produce one game within a week. Enjoy our 2D arcade game that we have produced!',
    github:'https://github.com/pippachoong/hip-and-hop-frontend',
    demo: 'https://hipandhop.netlify.app/' 
  },
  {
    id: 2, 
    image: DES,
    title: 'Despination',
    description: 'This is a team collaboration project where two of us decided to take the challenge to work with React and Ruby on Rails as both as our front-end and back-end frameworks (after learning React for just 2 days!) It is time to show you what we have learned in the past 8 weeks!',
    github:'https://github.com/pippachoong/despination-frontend',
    demo: 'https://despination.netlify.app/'
  },
  {
    id: 3, 
    image: BON,
    title: 'Bon Appetit',
    description: 'This is another individual project where I have spent only a week to build a full-stack application with Rails app by applying what I have learnt in the last 2 weeks about the back end development. Bon Appetit is a platform where people gather to find and share home cooked recipes where they have been tried, tested and ready to share it to the world with pride! ',
    github:'https://github.com/pippachoong/bon-appetit',
    demo: 'https://bonappepip.herokuapp.com/'
  },
   {
    id: 4, 
    image: TIC,
    title: 'Tic Tac Toe',
    description: 'This is a small project built just in 4 days after being taught about the fundamentals of web development such as HTML, CSS, Javascript and JQuery in just 2 weeks. Inspired by Candy Crush, this is a classic 3 x 3 board game for 2 players or can be played with computer challenge mode.',
    github:'https://github.com/pippachoong/tic-tac-toe',
    demo: 'https://pippachoong.github.io/tic-tac-toe/'
  },
]


const Portfolio = () => {
  return (
    <section id='portfolio'>

      
      <h1> Projects </h1>

      <div className="container portfolio_container">
        {
          data.map(({id,image,title,description,github,demo}) => {
            return (
              <article key={id} className='portfolio_item'>
                <div className='portfolio_item-image'> 
                  <img src={image} alt={title}/>
                  </div>
                <h3> {title} </h3>
                <h5> {description} </h5>
                <div className="portfolio_item-cta">
                  <a href={github}className='btn' target='_blank'>Github</a>
                  <a href={demo} className='btn btn-primary'target='_blank'>Live Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio