import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {FiUser} from 'react-icons/fi'
import {TbAward} from 'react-icons/tb'
import {FiPenTool} from 'react-icons/fi'
import {GoBook} from 'react-icons/go'
import {AiOutlinePhone} from 'react-icons/ai'
import {useState} from 'react'

const Nav = () => {
  // this active nav is to show the current nav section that you are viewing certain section when you click one of them with css styling
  const [activeNav, setActiveNav] = useState('#');
  return (
    <nav>
      <div className='tooltip'>
        <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><AiOutlineHome/></a>
        <span className='tooltiptext'>Home</span>
      </div>
      
      <div className='tooltip'>
        <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><FiUser/></a>
        <span className='tooltiptext'>About</span>
      </div>
      
      <div className='tooltip'>
        <a href="#skills" onClick={() => setActiveNav('#skills')} className={activeNav === '#skills' ? 'active' : ''}><FiPenTool/></a>
        <span className='tooltiptext'>Skills</span>
      </div>

      <div className='tooltip'>
        <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><TbAward/></a>
         <span className='tooltiptext'>Experience</span>
      </div>

      <div className='tooltip'>
        <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><GoBook/></a>
        <span className='tooltiptext'>Projects</span>
      </div>

      <div className='tooltip'>
        <a href="#footer" onClick={() => setActiveNav('#footer')} className={activeNav === '#footer' ? 'active' : ''}><AiOutlinePhone/></a>
        <span className='tooltiptext'>Get in touch</span>
      </div>
      
    </nav>
  )
}

export default Nav

