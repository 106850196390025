import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/profile-img-placeholder.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
        <div className="container header_container">
          <h2> Hello I'm </h2>
          <h1> Philippa!</h1>
          <h2 className="text-light"> I am </h2>
          <div className="dropping-texts">
            <div>a software enginner</div>
            <div>a former architect</div>
            <div>always curious </div>
            <div> passionate to learn </div>
          </div>
          <CTA />
          <HeaderSocials />
          

          <a href="#contact" className='scroll_down'> Nav bar on right /Scroll Down →</a>

        </div>
    </header>
  )
}

export default Header