import React from 'react'
import './footer.css'
import {FaLinkedinIn} from 'react-icons/fa'
import {BsGithub} from 'react-icons/bs'
import {MdOutlineEmail} from 'react-icons/md'
// import CV from '../../assets/Philippa Choong Resume.pdf'


const Footer = () => {

   const copy = () => {
    const email = "pippachoong@gmail.com"
    navigator.clipboard.writeText(email).then(promise => {
      alert("Email address copied!");
    });
  }
    

  return (

    <footer id='footer'>
      {/* <a href="#" className='footer_logo'>PHILIPPA</a> */}
      {/* <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#skills">Skills</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
      </ul> */}

      

      <h3> You can reach me at the following: </h3>
      <div className="footer_socials">
        <a href="https://www.linkedin.com/in/philippachoong" target="_blank"><FaLinkedinIn/></a>
        <a href="https://github.com/pippachoong" target="_blank"><BsGithub/></a>
        <div className="copy_email" onClick={copy}><MdOutlineEmail/></div>
      </div>

{/* 
      <div className='resume-section'>
          <p> Click here for my latest resume:</p>
         <div className='tooltip'>
          <a href={CV} target="_blank" className='btn_cv'> Resume </a>
          <span className='tooltiptext'>Click here!</span>
        </div>
      </div> */}

      <div className="footer_copyright">
        <small> Site made with React </small>
        <small>&copy;Philippa Choong 2022</small>
      </div>

    </footer>

  )
}

export default Footer